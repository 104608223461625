import { Outlet } from "react-router-dom";
import { Alert, AlertContext, Confirm, Loading, Separator } from "@moreirapontocom/npmhelpers";
import { useContext, useEffect, useState } from "react";
import { getProfile } from "../../services/profiles.service";
import { DataContext } from "../../context/Data.context";
import Navbar from "../../components/Navbar/Navbar.component";
import Footer from "../../components/Footer/Footer.component";

const Home = () => {
  const {data, setData} = useContext(DataContext);
  const {alert} = useContext(AlertContext);

  useEffect(() => {
    // If user refreshes the page, get user data from local storage
    // and update the context
    const user: any = localStorage.getItem("user");
    if (user) {
      setData({...data, user: JSON.parse(user)});
    }

    _getUserProfile();
  }, []);

  const [loadingProfile, setLoadingProfile] = useState(false);
  const _getUserProfile = async () => {
    setLoadingProfile(true);
    const response: any = await getProfile().then((r: any) => r).catch((e: any) => e);
    if (response.message === "OK") {

      // Update profile data in local storage
      const profileStorage: any = localStorage.getItem("user");
      if (profileStorage) {
        const profileData: any = JSON.parse(profileStorage);
        profileData.displayName = response.profile.displayName;
        localStorage.setItem("user", JSON.stringify(profileData));
      }

      // Update access_token with new data
      // When user authenticate, the JWT contains just email and displayName data.
      // When this page (home) is loaded, user profile is loaded and a new JWT is generated
      // This new JWT contains email, displayName and color data. We can add more data later.
      const access_token: any = localStorage.getItem("access_token");
      if (access_token) {
        localStorage.setItem("access_token", response.newAccessToken);
      }

      const currentTeam: any = localStorage.getItem("currentTeam");
      if (currentTeam) {
        setData({...data, currentTeam: JSON.parse(currentTeam)});
      }

      setData({
        ...data,
        user: {
          ...data.user,
          uid: response.profile.uid,
          email: response.profile.email,
          accessToken: response.newAccessToken,
          displayName: response.profile.displayName,
          color: response.profile.color,
        },
      });
      setLoadingProfile(false);
      return;
    }

    setLoadingProfile(false);
    console.log("Error getting user profile");
  };

  return <>
    <Navbar />
    <Alert alert={alert} />
    <Confirm />

    <Separator size={20} />

    <Loading loading={loadingProfile} />

    {!loadingProfile && <Outlet />}

    <Separator size={40} />

    <Footer />
  </>
};

export default Home;