import { Separator } from "@moreirapontocom/npmhelpers";
import { Link } from "react-router-dom";

const NewAccountSuccess = () => {
  return <div className="NewAccountSuccess container mt-5">
    <div className="text-center">

      <strong>Welcome to URL Collab!</strong>
      <Separator size={10} />

      <p>
        Your account has been created successfully.<br />
        Now you can start sharing links with your teams.
      </p>

      <Separator size={20} />

      <Link to="/login" className="btn btn-primary">Go to login &rarr;</Link>

      <Separator size={60} />
      <img src="/welcome_1.svg" alt="" className="img-fluid" style={{ maxWidth: "250px" }} />

      <Separator size={20} />
    </div>

    <Separator size={30} />
  </div>;
};

export default NewAccountSuccess;