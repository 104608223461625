import { Separator } from "@moreirapontocom/npmhelpers";

const Footer = () => {
  const appVersion: string =  process.env.REACT_APP_VERSION || "0.0.0";

  return <>
    <Separator size={40} />

    <footer>
      <div className="container Footer text-center">
        <small className="text-muted">
          <span className="opacity-50">
            &copy; 2025. criarti.com
            <span className="text-light">
              <br />v{appVersion}
            </span>
          </span>

          <Separator size={10} />

          <i className="fas fa-life-ring me-2"></i> Use our <a href="https://forms.gle/ytaR76SY1HUR85UN9" target="_blank" rel="noopener noreferrer">Support Form</a> to get help.

        </small>
      </div>
    </footer>

    <Separator size={40} />
  </>
};

export default Footer;