import { useContext } from "react";
import { Separator } from "@moreirapontocom/npmhelpers";
import { Link, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import { logout } from "../../services/auth.service";
import { DataContext } from "../../context/Data.context";
import User from "../../interfaces/user.interface";

const Navbar = () => {
  const {data, setData} = useContext(DataContext);
  const navigate: any = useNavigate();

  const CustomLink = (props: any) => {
    const { to, children } = props;
    let resolved: any = useResolvedPath(to);
    let match: any = useMatch({ path: resolved.pathname, end: true });

    return (
        <Link data-testid={`navbar-${to.replace('/','')}`} className={`nav-link ${match ? "active" : ""}`} to={to}>
          {children}
        </Link>
    );
  };

  const _callLogout = async () => {
    await logout();

    const emptyUser: User = {
      uid: "",
      displayName: "",
      email: "",
      accessToken: "",
    };

    setData({...data, user: emptyUser});
    navigate("/login");
    return;
};

  return (
    <nav className="navbar navbar-expand-lg mt-3 mb-3">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src="https://criarti.com/c@2x.png" alt="Criarti" className="img-fluid" style={{ width: "40px" }} />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-lg-0 text-center">
            <li className="nav-item me-3">
              <CustomLink to="/">
                <i className="fas fa-rss me-2"></i> Feed
              </CustomLink>
            </li>
            <li className="nav-item me-3">
              <CustomLink to="/teams">
                <i className="fas fa-list me-2"></i> Teams
              </CustomLink>
            </li>
            <li className="nav-item me-3">
              <CustomLink to="/profile">
                <i className="fas fa-user-circle me-2"></i> Profile
              </CustomLink>
            </li>
            <li className="nav-item">
              <button type="button" className="nav-link" onClick={() => _callLogout()}>
                <i className="fas fa-sign-out-alt me-2"></i> Sign Out
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;